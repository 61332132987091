import React, { useEffect, useState, useRef } from "react";
import { useUserAuth } from "../../firebase-auth";
import { logOauth2Error } from "../../firebase-firestore";
import { useNavigate } from "react-router-dom";
import { accessLog } from "../../firebase-functions";
import Loading from "../../components/loading";
import { useTranslation } from "react-i18next";
import MultiFactorSignIn from "../../components/MultiFactorSignIn";

function Login(props) {
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(false);
  const emailInputRef = useRef(null);
  const auth = useUserAuth();
  const { signIn, user, handleMultiFactorSignIn } = auth;
  const queryString = new URL(window.location).searchParams;
  const [auth_token, setAuth_Token] = useState(queryString.get("authToken"));
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailModal, setEmailModal] = useState("");
  const [passwordModal, setPasswordModal] = useState("");
  const [modalSubmitFlag, setModalSubmitFlag] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const errorData = {
    email: user?.email || "",
    name: user?.DisplayName || "",
    action: "Login",
    occurence: new Date(Date.now()),
  };

  const { t } = useTranslation();
  const [shouldMfaStart, setShouldMfaStart] = useState(false);
  const [mfaResolver, setMfaResolver] = useState();
  const [mfaAlert, setMfaAlert] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    emailInputRef.current.focus();
  }, []);

  const onDone = async () => {
    const authorize = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      response_type: "token",
      scope: "profile",
    };
    const authParams = new URLSearchParams(authorize);
    if (!auth_token) {
      window.location.replace(
        `${process.env.REACT_APP_FUNCTIONS_URL}/authorize/entry?${authParams}`,
      );
    }
  }

  // useEffect(() => {
  //   console.log("auth token", auth_token);
  //   const authorize = {
  //     client_id: process.env.REACT_APP_CLIENT_ID,
  //     redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  //     response_type: "token",
  //     scope: "profile",
  //   };
  //   const authParams = new URLSearchParams(authorize);
  //   if (!auth_token) {
  //     window.location.replace(
  //       `${process.env.REACT_APP_FUNCTIONS_URL}/authorize/entry?${authParams}`,
  //     );
  //   }
  // }, [auth_token]);

  useEffect(() => {
    console.log("error: ", error, "mfaAlert: ", mfaAlert);
    setTimeout(() => {
      // setError(false);
      setMfaAlert();
    }, 6000);
  }, [mfaAlert, error]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPending(true);

    const emailToUse = modalSubmitFlag === 1 ? emailModal : email;
    const passwordToUse = modalSubmitFlag === 1 ? passwordModal : password;

    try {
      //modalSubmitFlag ===1 ? await signIn(emailModal, passwordModal) : await signIn(email, password)
      await signIn(emailToUse, passwordToUse)
        .then((response) => {
          console.log("response", response);
          accessLog({ uid: response.user.uid, origin: "12MillionPlus" });
          if (response.error) {
            setPending(false);
          }
        })
        .catch((error) => {
          setPending(false);

          if (error.code === "auth/multi-factor-auth-required") {

            setIsModalOpen(false);
            handleMultiFactorSignIn(
              error,
              setShouldMfaStart,
              setMfaResolver,
              setMfaAlert
            );
          }
          if (error.message.includes("user-not-found")) {
            setError("No account found Please sign up to proceed further");
          }
          if (error.message.includes("wrong-password")) {
            setError("Password is incorrect");
          }
          if (error.message.includes("too-many-requests")) {
            setError(
              "To many failed login attempts you can try again later or reset password");
          }
          const errorInstance = {
            ...errorData,
            email,
            description: "Error with login function",
            error: error?.message?.toString(),
          };
          logOauth2Error(errorInstance);
        });
    } catch (error) {
      setPending(false);
      setError(error.message);
      const errorInstance = {
        email,
        description: "Error in form submit",
        error: error?.message?.toString(),
      };
      logOauth2Error(errorInstance);
    }
  };

  const loginModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <>
      {pending ? (
        <Loading />
      ) : (
        <div className="xs:w-3/4 xs:gap-4 xs:m-auto flex flex-col gap-10 items-center mr-10">
          <form className="flex flex-col gap-4 items-left xs:gap-4 xs:m-auto" onSubmit={handleSubmit}>
            <div>
              <h4 className="font-lato font-black text-4xl xs:text-l text-gray-700">
                {t("Login")}
              </h4>
              <p className="text-gray-500 text-l mt-2">
                Please enter your details to sign in to your account
                </p>
                <div className="my-4 w-full flex flex-col items-center justify-center text-center cursor-pointer" >
              <p className="text-sm text-[#757575]">
              If you're already a member of our Instant Teams talent community, you're all set!<br/> Simply use the same login credentials here to get started.          </p>
              {/* <img
                src={"/../images/InstantTeamsIcon-05.png"}
                alt="logo"
                className="mt-2 xs:w-5 xs:h-5 w-[56px] h-[56px]"
              /> */}
            </div>
            </div>

            <div className="flex flex-col gap-2 xs:w-full">
              <div className="relative">
                <input
                  ref={emailInputRef}
                  onChange={(e) => { setEmail(e.target.value); setError(false) }}
                  id="email"
                  type="email"
                  value={email}
                  required
                  pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}$"
                  className="block px-2.5 pb-3.5 py-3 w-full text-sm border border-community-input-field-border-primary text-gray-900 bg-white rounded-lg border-1 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-community-input-field-border-primary focus:outline-none focus:ring-0 focus:border-community-input-field-border-primary peer"
                  autoComplete="off"
                />
                {!shouldMfaStart && (
                  <label
                    htmlFor="email"
                    className="absolute text-sm text-community-input-field-border-primary dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-1 peer-focus:px-1 peer-focus:text-community-input-field-border-primary peer-focus:dark:text-community-input-field-border-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 ml-3"
                  >
                    Email ID
                  </label>
                )}
              </div>

              <div className="relative mt-2">
                <input
                  id="password"
                  type="password"
                  name="pass"
                  required
                  value={password}
                  onChange={(e) => { setPassword(e.target.value); setError(false) }}
                  className="block px-2.5 pb-3.5 py-3 w-full text-sm border border-community-input-field-border-primary text-gray-900 bg-transparent rounded-lg border-1 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-community-input-field-border-primary focus:outline-none focus:ring-0 focus:border-community-input-field-border-primary peer"
                  autoComplete="off"
                />
                {!shouldMfaStart && (
                  <label
                    htmlFor="password"
                    className="absolute text-sm text-community-input-field-border-primary dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-1 peer-focus:px-1 peer-focus:text-community-input-field-border-primary peer-focus:dark:text-community-input-field-border-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 ml-3"
                  >
                    Password
                  </label>
                )}
              </div>
              {(error && modalSubmitFlag === 0) && <p className="text-red-500 text-[12px]">{error}</p>}
            </div>
            <div className="w-full flex justify-end text-center">
              <a href="/community/password/email">
                <p className="text-sm text-[#757575]">{t("Forgot Password")}</p>
              </a>
            </div>
            <div className="mt-1 w-full flex flex-col items-center justify-center text-center cursor-pointer" >
              <p className="text-sm text-[#757575]">
              This exclusive community welcomes all verified Active Duty, Veteran,<br/> Retiree, National Guard, Reserve, and Gold Star spouses.              </p>
              {/* <img
                src={"/../images/InstantTeamsIcon-05.png"}
                alt="logo"
                className="mt-2 xs:w-5 xs:h-5 w-[56px] h-[56px]"
              /> */}
            </div>
            <div className="flex flex-col items-center justify-center xs:w-full">
              <button
                className="bg-[#19415E] text-white py-4 font-normal w-96 xs:w-full xs:text-lg rounded-[87px] focus:outline-none focus:shadow-outline mt-3"
                type="submit"
                disabled={pending}
              >
                {t("Login")}
              </button>
            </div>
            {/* <div className="mt-1 w-full flex flex-col items-center justify-center text-center cursor-pointer" >
              <p className="text-sm text-[#757575]">
              This exclusive community welcomes all verified Active Duty, Veteran, Retiree, National Guard, Reserve, and Gold Star spouses.              </p>
              {/* <img
                src={"/../images/InstantTeamsIcon-05.png"}
                alt="logo"
                className="mt-2 xs:w-5 xs:h-5 w-[56px] h-[56px]"
              /> 
            </div> */}
            <div className="mt-3 w-full flex justify-center text-center">
              <p className="text-sm text-[#757575]">{t("Don’t have an account? ")}</p>
              <a href="/community/verification" className="flex">
                <p className="ml-1 text-[15px] text-[#23415C] font-bold">{t("Sign Up")}</p>
              </a>
            </div>
          </form>
         

          {isModalOpen && (

            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60" style={{ zIndex: 1000 }}>
              <div className="relative bg-white p-6 rounded-lg shadow-xl w-full max-w-md mx-4 sm:mx-6 sm:w-2/3 md:w-1/2 lg:w-1/3" role="alert">
                <button
                  className="absolute top-3 right-3 w-9 h-9 rounded-full border border-gray-300 text-gray-400 flex items-center justify-center"
                  onClick={closeModal}
                >
                  &times;
                </button>
                <form className="flex flex-col gap-4 items-left xs:gap-4 xs:m-auto" onSubmit={handleSubmit}>
                  <div className="flex items-center justify-center">
                    <img
                      src="/../images/InstantTeamsIcon-05.png"
                      alt="logo"
                      className="mt-[5%] xs:w-5 xs:h-5 w-[56px] h-[56px] justify-center"
                    />
                  </div>
                  <div className="h-4"></div>

                  <div className="flex flex-col gap-2 xs:w-full">
                    <div className="relative">
                      <input
                        ref={emailInputRef}
                        onChange={(e) => { setEmailModal(e.target.value); setError(false); }}
                        id="emailmodal"
                        type="email"
                        value={emailModal}
                        required
                        pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}$"
                        className="block px-2.5 pb-3.5 py-3 w-full text-sm border border-community-input-field-border-primary text-gray-900 bg-white rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-community-input-field-border-primary peer"
                        autoComplete="off"
                      />
                      <input
                        id="emailmodal"
                        type="hidden"
                        value={modalSubmitFlag}
                        required
                      />
                      {!shouldMfaStart && (
                        <label
                          htmlFor="emailmodal"
                          className="absolute text-sm text-community-input-field-border-primary duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white px-1 peer-focus:px-1 peer-focus:text-community-input-field-border-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-3"
                        >
                          Email ID
                        </label>
                      )}
                    </div>

                    <div className="relative mt-2">
                      <input
                        id="passwordmodal"
                        type="password"
                        name="pass"
                        required
                        value={passwordModal}
                        onChange={(e) => { setPasswordModal(e.target.value); setError(false); }}
                        className="block px-2.5 pb-3.5 py-3 w-full text-sm border border-community-input-field-border-primary text-gray-900 bg-transparent rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-community-input-field-border-primary peer"
                        autoComplete="off"
                      />
                      {!shouldMfaStart && (
                        <label
                          htmlFor="passwordmodal"
                          className="absolute text-sm text-community-input-field-border-primary duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white px-1 peer-focus:px-1 peer-focus:text-community-input-field-border-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-3"
                        >
                          Password
                        </label>
                      )}
                    </div>
                    {error && <p className="text-red-500 text-[12px]">{error}</p>}
                  </div>

                  <div className="flex flex-col items-center">
                    <button
                      className="bg-[#19415E] text-white py-4 font-normal w-full xs:w-full xs:text-lg rounded-[87px] focus:outline-none focus:shadow-outline mt-3"
                      type="submit"
                      disabled={pending}
                      onClick={(e) => { setModalSubmitFlag(1); }}
                    >
                      {t("Continue")}
                    </button>
                  </div>
                  <div className="h-4"></div>
                </form>
              </div>
            </div>


          )}
        </div>
      )}
       {shouldMfaStart && (
            <div className="fixed w-full flex p-10 h-full justify-center bg-black bg-opacity-50 top-0 left-0">
              <div className="absolute mx-1 left-1/2 items-center top-[20%] border bg-white shadow-xl w-[33%] p-6 rounded xs:w-screen -translate-x-1/2 flex flex-col justify-center overflow-hidden" role="alert">
                <MultiFactorSignIn
                  mfaResolver={mfaResolver}
                  onFormNotify={setMfaAlert}
                  setShouldMfaStart={setShouldMfaStart}
                  onDone={onDone}
                />
              </div>
            </div>
          )}
    </>
  );
}

export default Login;
