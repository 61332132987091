import { useEffect, useState } from "react";
import { LANGUAGES } from "../constants";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";


function Header({ headerH2, headerH2_Line2, headerText }) {
  const [show, setShow] = useState(true);
  const { i18n, t } = useTranslation();
  
  const isThatSheerIDVerificationPage  = window.location.href.includes("verification")

  const [heading, setHeading] = useState({
    line1: "verification_line_1",
    line2: "verification_line_2",
    line3: "verification_line_3"
  })
  useEffect(() => {
   console.log(window.location.pathname.split("/").at(-1))
   switch (window.location.pathname.split("/").at(-1)) {
    case "verification-failed":
      setHeading({
        line1: "Instant Teams Community",
        line2: "verificationFailed_line_2",
        line3: "verificationFailed_line_3"
      })
      break;
    case "registration":
      setHeading({
        line1: "registration_line_1",
        line2: "",
        line3: "registration_line_3"
      })
      break;
    case "login":
      setHeading({
        line1: "login_line_1",
        line2: "Instant Teams Community",
        line3: "login_line_3"
      })
      break;
    case "profile":
      setHeading({
        line1: "profile_line_1",
        line2: "",
        line3: "profile_line_3"
      })
      break;
    case "email":
      setHeading({
        line1: "login_line_1",
        line2: "Instant Teams Community",
        line3: "login_line_3"
      })
      break;
    default:
      setHeading({
        line1: "verification_line_1",
        line2: "verification_line_2",
        line3: "verification_line_3"
      })
   }
  },[])


  const onChangeLanguage = (e) => {
    e.preventDefault();
    const language = e.target.value;
    i18n.changeLanguage(language);
  };
  
  return (
    <main className="h-screen  md:flex-row md:items-center md:justify-center  flex xs:flex-col"  >
      
<div className={`bg-community-pattern bg-cover xs:w-screen h-screen xs:h-3/5 xs:pt-8 w-1/2 ${isThatSheerIDVerificationPage ? "xs:hidden md:hidden  flex" : "flex"} flex-col items-center justify-center mb-8`}>      
        <h1 className="text-[#284faa] xs:hidden md:hidden my-5 text-2xl font-semibold"> Welcome To The </h1>
  
        <div className="w-full flex justify-center items-center">
        <img
        src={"/../images/logo-full.png"}
        alt="logo"
        className="mt-[5%]  w-[85%] h-[80%] justify-center"
      />
        </div>
     
      {/* {!window.location.hostname.includes("community.instantteams.com") && (
        <>
        <button 
        onClick={() => {setShow(!show)}}
        className="text-left self-start pl-6">X Close</button>
        <div className={show ? "bg-red-500 text-white font-extrabold px-8 py-4 ": " hidden"}>
        <h1 className="text-center text-5xl"
        >
          SSO STAGING ENVIROMENT
        </h1>
        </div>
        </>
        
      )} */}
      <div className="">
        {/* <select className="rounded-md border-2" defaultValue={i18n.language} onChange={onChangeLanguage}>
        {LANGUAGES.map(({label, code}) => (
          <option key={code} value={code}>
            {label}
          </option>
        )
        )}
        </select> */}
      </div>
      {/* <h4 className="xs:text-xl text-5xl text-community-text-primary font-inter text-center font-[700]">
        {t(heading.line1)}
      </h4>
      <h4 className="xs:text-xl xs:pb-2 text-5xl text-community-text-primary font-inter text-center  font-[700] pb-6">
        {t(heading.line2)}
      </h4> */}
        <div className="flex justify-center items-center w-full">
        <p className="xs:text-base leading-[25px] text-center  mt-16 xs:mt-4  md:mt-4 xs:pb-4  md:pb-4 text-[20px] text-gray-500 font-light    w-[60%]">
        {t(heading.line3)}
      </p>
        </div>
     
     
      
      </div>
      <div className="xs:w-screen xs:px-4  md:flex-row md:items-center md:justify-center  md:px-4 xs:h-full w-1/2 flex flex-col gap-6 justify-center  ">
        <Outlet />
      </div>
    </ main>
  );
}

export default Header;
